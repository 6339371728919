// grid, one column mobile, two columns desktop, gap 5px, breakpoint: +susy-breakpoint($medium)
.experience-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 15px; // gap X
	grid-row-gap: 0; // no gap Y
	@include susy-breakpoint($medium) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 30px; // gap X
		grid-row-gap: 0; } } // no gap Y

.experience-filter-tools {
	@apply text-lg {}
	@include susy-breakpoint($medium) {
		@apply flex {} }

	input,
	select {
		@apply border-grey border-solid border px-4 py-2 pt-3 mr-2 appearance-none shadow-md w-full {}
		font-family: 'HamburgTS';
		@include susy-breakpoint($medium) {
			min-width: 300px; } }

	.search-input {
		@apply mb-4 {}
		@include susy-breakpoint($medium) {
			@apply mr-5 {} }
		option {
			@apply text-grey {} } }

	.filter-dropdown {
		@apply relative mb-4 {}
		.filter-arrow {
			@apply absolute pin-t pin-r pin-b flex justify-center items-center w-16 text-grey pointer-events-none {} } } }


.experience-image {
	object-fit: cover;
	display: block; }
