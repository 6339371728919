#listing {
	.filter-nav {
		@include susy-breakpoint($medium) {
			@apply .flex .justify-between {} }

		span.sort {
			cursor: pointer;
			@apply .border-transparent .border-b-4 {}
			&.asc,
			&.desc {
				@apply .border-white .border-b-4 {} } }

		label {
			@apply .mr-4 {} }

		span.sort {
			@apply .mx-4 {} }


		label, span.sort {
			@apply .inline-block {} }

		.search-field {
			@apply .flex .justify-between .my-4 {}
			@include susy-breakpoint($medium) {
				@apply .inline .my-0 {} }
			input {
				border: none;
				padding: 0;
				background: none;
				font-weight: 300;
				width: 100%;
				@include susy-breakpoint($medium) {
					width: auto;
					@apply .mx-4 {}
					text-align: right; }
				&::placeholder {
					opacity: 1;
					color: $black; }
				&:focus::placeholder {
					opacity: 0; } }

			i {
				cursor: pointer; } }

		.sort-display {
			width: 1rem;
			position: relative;
			i {
				position: absolute;
				opacity: 0; }
			@apply .text-sm {} }
		.asc {
			i.is-asc {
				opacity: 1; } }
		.desc {
			i.is-desc {
				opacity: 1; } } }

	.items-header {
		display: none;
		@include susy-breakpoint($medium) {
			@apply .flex .bg-black .text-white .justify-between .mt-12 {} }

		label {
			@apply .px-8 .py-4 {} } }

	#collection-list {
		@include susy-breakpoint($medium) {
			.title {
				width: 50%; }
			.date {
				width: 25%; }
			.type {
				width: 25%; } } }

	.list {
		article {
			@apply .bg-white .my-4 .p-4 {}
			@include susy-breakpoint($medium) {
				@apply .flex .justify-between .p-0 .my-8 {} }

			& > div {
				@include susy-breakpoint($medium) {
					@apply .p-8 {} } } } } }


.barba-container .content ul.pagination {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	justify-content: center;

	li {
		padding: 0 1rem;

		&.active {
			font-weight: bold; } } }

#matter,
#asset {
	label {
		display: block;
		font-weight: bold;
		@include susy-breakpoint($medium) {
			font-weight: normal;
			display: inline-block;
			min-width: 220px; }

		&.spacer {
			display: none;
			@include susy-breakpoint($medium) {
				display: inline-block; } } }

	.item-details {
		@include susy-breakpoint($medium) {
			@apply .flex .justify-between {}
			& > div {
				flex-basis: 50%;
				&:first-child {
					@apply .mr-8 {} }

				&:last-child {
					@apply .ml-8 {} } } } }

	.downloads {
		.download-box {
			@apply .bg-white .p-4 .block .mb-4 {}
			@include susy-breakpoint($medium) {
				@apply .flex .items-center .p-8 {} } }

		span {
			@apply .block {}
			@include susy-breakpoint($medium) {
				@apply .px-8 {} } }

		.download-title {
			@include susy-breakpoint($medium) {
				width: 50%;
				font-weight: 600; } }

		.download-date {
			@apply .text-base {}
			@include susy-breakpoint($medium) {
				@apply .text-xl {} } }

		i {
			font-size: 1.8rem;
			@include susy-breakpoint($medium) {
				font-size: 2.3rem; } }

		.file-icon {
			@apply .text-black {}
			@include susy-breakpoint($medium) {
				width: 4rem;
				height: 4rem;
				position: relative;
				@apply .bg-black .text-white .inline-block .p-4 {}

				i.fa-file-pdf {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%); } } }


		span.download-link {
			display: none;
			@include susy-breakpoint($medium) {
				display: block;
				margin-left: auto;
				padding-right: 0; } } }


	.gallery {
		a.image {
			flex-basis: 50%;
			@include susy-breakpoint($small) {
				flex-basis: 33.333%; }
			@include susy-breakpoint($medium) {
				flex-basis: 25%; } }
		img {
			width: calc(100% - 2rem); } } }

.filter-tools {
	@apply text-lg {}
	@include susy-breakpoint($medium) {
		@apply flex {} }

	input,
	select {
		@apply border-grey border-solid border px-4 py-2 pt-3 mr-2 appearance-none shadow-md w-full {}
		font-family: 'HamburgTS';
		@include susy-breakpoint($medium) {
			min-width: 300px; } }

	.search-field {
		@apply mb-4 {}
		@include susy-breakpoint($medium) {
			@apply mr-5 {} }
		option {
			@apply text-grey {} } } }
