@tailwind preflight {}

@tailwind components {}

@tailwind utilities {}

.multiply {
	@include susy-breakpoint($medium) {
		mix-blend-mode: multiply; } }

.force-multiply {
	mix-blend-mode: multiply; }

.hide {
	opacity: 0; }

.bg-yellow-blend {
	@include IE10Plus {
		@include susy-breakpoint($medium) {
			background: linear-gradient(to bottom, rgba(252,218,1,0.5) 0%,rgba(252,218,1,1) 10%,rgba(252,218,1,1) 100%); } } }

.bg-yellow-longer-blend {
	@include IE10Plus {
		@include susy-breakpoint($medium) {
			background: linear-gradient(to bottom, rgba(252,218,1,0.5) 0%,rgba(252,218,1,1) 30%,rgba(252,218,1,1) 100%); } } }
