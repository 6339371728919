#home {
	.page-logo {
		width: 90%;
		max-width: 160px;
		padding-top: 2rem;
		transition: opacity 0.3s ease;
		display: none;
		@include susy-breakpoint($small) {
			display: block; }
		@include susy-breakpoint($large) {
			max-width: 250px;
			padding-top: 5rem; } }

	.page-title {
		max-width: 300px;
		position: absolute;
		bottom: -202px;
		@include susy-breakpoint($small) {
			bottom: -202px; }
		@include susy-breakpoint($large) {
			bottom: -261px;
			max-width: 500px; }
		@include susy-breakpoint($xlarge) {
			bottom: -325px; } }

	.home-content {
		padding-top: 16rem;
		@include susy-breakpoint($small) {
			padding-top: 16rem; }
		@include susy-breakpoint($large) {
			padding-top: 22rem; }
		@include susy-breakpoint($xlarge) {
			padding-top: 28rem; } }

	.home-portals {
		@include susy-breakpoint($large) {
			min-height: 1200px; } }

	.half-box {
		@apply .py-1 {}
		@include susy-breakpoint($large) {
			max-width: 48%; }

		&.right {
			@include susy-breakpoint($large) {
				/*max-width: 800px*/
				float: right; } } }


	.solvency-cta {
		@apply .text-center .px-4 .flex .items-center {}
		min-height: 400px;
		@include susy-breakpoint($small) {
			min-height: 600px; }
		@include susy-breakpoint($medium) {
			min-height: 800px; }

		.solvency-cta-content {
			width: 100%;
			@include susy-breakpoint($large) {
				@include container(600px); }
			h2 {
				@apply .mb-4 {} } } }

	.latest-news-widget {
		padding-bottom: 2rem;
		@include susy-breakpoint($medium) {
			padding-bottom: 8rem; }

		.news-posts {
			@include susy-breakpoint($medium) {
				display: flex; } }

		.news-post {
			flex-basis: 50%;
			@apply .bg-white .p-8 .my-8 {}

			@include susy-breakpoint($medium) {
				&:first-child {
					@apply .mr-4 {} }

				&:last-child {
					@apply .ml-4 {} } }

			date {
				@apply .block .my-4 {} } } }

	.trigger-accordion {
		position: relative;
		z-index: 2;

		h2 {
			cursor: pointer; } }

	.offset-image {
		position: relative;
		z-index: 1;
		transition: filter 0.3s ease;
		filter: saturate(0);
		@include susy-breakpoint($large) {
			position: absolute;
			top: 22rem;
			right: 2rem; }
		@include susy-breakpoint($xlarge) {
			top: 28rem; }

		&:hover {
			filter: saturate(1); } } }
